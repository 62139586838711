import React from "react";
import "./404.css"
const BadPage = () => {
  return (
    <>
      <div className="parallax_404">
      </div>
    </>
  );
};
export default BadPage;
