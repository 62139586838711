import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import "./privacy_policy.css";

const PrivacyPolicy = () => {
  return (
    <Row align="center">
      <Col>
        <Card className="privacyHolder">
          <Card.Body>
            <Row className="text-center">
              <Col>
                <h1>Privacy Policy for PRAMS Plus Mobile Applications</h1>
                <p>Last Updated: 12/13/2023</p>

                <p>
                  Welcome to PRAMS mobile applications. This Privacy Policy
                  explains how we collect, use, disclose, and safeguard your
                  information when you visit our website or use our mobile
                  application. Please read this Privacy Policy carefully. By
                  accessing or using our services, you agree to the terms
                  outlined in this policy.
                </p>
                <h2>Information We Collect</h2>
                <h5>1. Personal Information</h5>
                <p>
                  We may collect the following personal information when you use
                  our services:
                  <ul>
                    <li style={{listStyle: 'none'}}>First and last name</li>
                    <li style={{listStyle: 'none'}}>email address</li>
                  </ul>
                </p>
                <h5>2. Non-Personal Information</h5>
                <p>
                  We may also collect non-personal information, such as:
                  <ul>
                    <li style={{listStyle: 'none'}}>device information</li>
                    <li style={{listStyle: 'none'}}>IP address</li>
                  </ul>
                </p>
                <h5>3. How We Collect Information</h5>
                <p>
                  We collect information in the following ways:
                  <ul>
                    <li style={{listStyle: 'none'}}>
                      Directly from You: When you provide information to us
                      through forms, registrations, or other interactions.
                    </li>
                    <li style={{listStyle: 'none'}}>
                      Automatically: Through technologies like cookies and
                      similar tracking technologies.
                    </li>
                  </ul>
                </p>
                <h5>4. Use of Information</h5>
                <p>
                  We may use the collected information for the following
                  purposes:
                  <ul>
                    <li style={{listStyle: 'none'}}>
                      To provide and improve our services, personalize user
                      experience
                    </li>
                  </ul>
                </p>
                <h5>5. Disclosure of Information </h5>
                <p>
                  We may disclose your information to third parties in the
                  following situations:
                  <ul>
                    <li style={{listStyle: 'none'}}>With your consent</li>
                    <li style={{listStyle: 'none'}}>To comply with legal obligations</li>
                    <li style={{listStyle: 'none'}}>To service providers</li>
                  </ul>
                </p>
                <h5>6. Security</h5>
                <p>
                  We take reasonable measures to protect your information from
                  unauthorized access or disclosure.
                </p>
                <h5>7. Your Choices</h5>
                <p>
                  You can manage your information and communication preferences
                  by:
                  <ul>
                    <li style={{listStyle: 'none'}}>Using in-app configuration tool</li>
                  </ul>
                </p>
                <h5>8. Third-Party Links</h5>
                <p>
                  Our website or app may contain links to third-party websites.
                  We are not responsible for the privacy practices of such
                  sites.
                </p>
                <h5>9. Changes to this Privacy Policy</h5>
                <p>
                  We may update this Privacy Policy from time to time. The
                  latest version will be posted on this page.
                </p>
                <h5>10. Contact Us</h5>
                <p>
                  If you have any questions or concerns about this Privacy
                  Policy, please contact us at support@pramsplus.com.
                </p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default PrivacyPolicy;
