import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faSquarePhone,
  faCopyright,
} from "@fortawesome/free-solid-svg-icons";
import "./Solutions.css";

const Solutions = () => {
  return (
    <>
      <div className="parallax_solutions_first"></div>
      <Row id="data-capture" style={{ width: "100%", margin: 0 }}>
        <Card id="card-holder-white-solutions">
          <Card.Body>
            <Row align="center">
              <h1> Field Data Capture</h1>
            </Row>
            <br />
            <Row className="d-flex justify-content-md-center">
              <Col align="center">
                <p
                  style={{
                    fontSize: "18px",
                    fontFamily: "Roboto, sans-serif",
                    paddingLeft: "35px",
                    paddingRight: "35px",
                  }}
                >
                  With the countless ways operators can capture and record
                  production data, the organization needs to implement a
                  recording process that best fits their operating environment
                  and culture. PRAMS was designed with this in mind. Users
                  customize exactly what they want to capture for each well, as
                  well as in the order that is best suited for each
                  route/pumper. Daily field data is entered in the field, the
                  office or imported from SCADA and 3rd party systems.
                  Consistent and accurate daily information is the foundation
                  for optimizing production.
                </p>
              </Col>
            </Row>
            <br />
            <Row>
              <Col align="d-flex align-items-center">
                <img
                  id="solutions_pic_1"
                  src="solutions_pic_1.jpeg"
                  alt="placeholder"
                />
              </Col>
              <Col>
                <br />
                <Row align="center">
                  <h3>Features</h3>
                </Row>
                <br />

                <Row>
                  <Col
                    style={{
                      fontSize: "18px",
                      fontFamily: "Roboto, sans-serif",
                    }}
                  >
                    <ul>
                      <li>Route-Based Data Entry</li>
                      <li>User Defined Layouts for Every Well</li>
                      <li>Equipment Tracking – User Configured</li>
                      <li>Numerous Gas Meter Types</li>
                      <li>Downtime Tracking</li>
                    </ul>
                  </Col>
                  <Col
                    style={{
                      fontSize: "18px",
                      fontFamily: "Roboto, sans-serif",
                      paddingRight: "55px",
                    }}
                  >
                    <ul>
                      <li>3rd Party & SCADA Import</li>
                      <li>Pressures (Casing, Tubing, Bradenhead, Line)</li>
                      <li>API Corrected Run Ticket Entry</li>
                      <li>Multi-tiered Daily Data Allocation</li>
                      <li>
                        Facility, AVO, and APCE Inspections – User Defined
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>
      <div className="parallax_solutions_two"></div>
      <Row id="production-accounting">
        <Card id="card-holder-blue-solutions">
          <Card.Body>
            <Row>
              <Col style={{ minWidth: "300px" }}>
                <Row align="center">
                  <h1> Production Accounting</h1>
                </Row>
                <br />
                <Row>
                  <p
                    align="center"
                    style={{
                      fontSize: "18px",
                      fontFamily: "Roboto, sans-serif",
                      paddingLeft: "45px",
                      paddingRight: "45px",
                    }}
                  >
                    PRAMS is the most versatile upstream production accounting
                    and reporting tool available for the small to mid-tier Oil &
                    Gas Operator. The ability to easily and accurately deliver
                    timely internal, partner, and regulatory reports is a must
                    for any operator. PRAMS was designed with the flexibility to
                    interface with endless 3rd party software packages. This
                    creates a software synergy that streamlines data reporting
                    and compels efficiency. PRAMS allows the user to efficiently
                    review and finalize monthly production and then provide that
                    information internally and externally. When you are ready to
                    submit your agency reports all it takes is a few clicks of
                    the mouse to generate both the official printed copies and
                    EDI files for upload to the agencies.
                  </p>
                </Row>
                <Row align="center">
                  <h3>Features</h3>
                </Row>
                <br />
                <Row className="justify-content-md-center">
                  <Col
                    xs={4}
                    className="d-flex align-items-center"
                    style={{
                      minWidth: "250px",
                      fontSize: "18px",
                      fontFamily: "Roboto, sans-serif",
                    }}
                  >
                    <ul>
                      <li>Company Web Portal</li>
                      <li>Partner Web Reporting</li>
                      <li>Purchase Statement Imports</li>
                      <li>Operated and Non-Operated</li>
                      <li>Fully Integrated Regulatory Agency Reporting</li>
                    </ul>
                  </Col>
                  <Col
                    xs={4}
                    className="d-flex align-items-center"
                    style={{
                      minWidth: "250px",
                      fontSize: "18px",
                      fontFamily: "Roboto, sans-serif",
                      paddingRight: "35px",
                    }}
                  >
                    <ul>
                      <li>Multi-tiered allocations</li>
                      <li>Water Disposition Tracking</li>
                      <li>Seamless 3rd Party Software Integration</li>
                      <li>
                        Reconcile Monthly Purchase Statements to Daily Sales
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row align="center">
              <Col
                align="center"
                // className="d-flex align-items-center"
                style={{ minWidth: "300px" }}
              >
                <img
                  id="solutions_pic_2"
                  src="solutions_pic_2.jpeg"
                  alt="placeholder"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>
      <div className="parallax_solutions_three"></div>
      <Row id="production-reporting">
        <Card id="card-holder-white-solutions">
          <Card.Body>
            <Row>
              <Row align="center">
                <h1> Production Reporting / Visualization</h1>
              </Row>
              <br />
              <br />
              <Row className="justify-content-md-center">
                <Col align="center" style={{ minWidth: "250px" }}>
                  <p
                    align="center"
                    style={{
                      fontSize: "18px",
                      fontFamily: "Roboto, sans-serif",
                      paddingLeft: "45px",
                      paddingRight: "45px",
                    }}
                  >
                    Sharing production and operational data internally and with
                    outside partners with automated reporting that is easy to
                    use with PRAMS. This client-driven aspect of PRAMS
                    consistently and automatically distributes necessary
                    production data to any email address hourly, daily, weekly,
                    monthly, or at a customized time-interval. Analysts and
                    operators no longer need to remember which data report to
                    share internally or with stakeholders, nor do they need to
                    remember when to share it. By eliminating repetitive
                    reporting, PRAMS increases process efficiency by providing
                    the capability to automate countless reporting tasks.
                  </p>
                </Col>
              </Row>
            </Row>
            <Row>
              <Col align="center">
                <img
                  id="solutions_pic_3"
                  src="solutions_pic_3.png"
                  alt="placeholder"
                />
              </Col>
              <Col>
                <Row align="center">
                  <h3>Features</h3>
                </Row>
                <br />
                <Row>
                  <Col
                    style={{
                      fontSize: "18px",
                      fontFamily: "Roboto, sans-serif",
                    }}
                  >
                    <ul>
                      <li>
                        Pinpoint, Extract, and Export Volume Data Automatically
                      </li>
                      <li>
                        Customizable Report Scheduling (Hourly, Weekly, Monthly,
                        etc.)
                      </li>
                    </ul>
                  </Col>
                  <Col
                    style={{
                      fontSize: "18px",
                      fontFamily: "Roboto, sans-serif",
                      paddingRight: "55px",
                    }}
                  >
                    <ul>
                      <li>Define Specific Well Lists for Partner Reporting</li>
                      <li>All Reports Available in Both XLSX and PDF Format</li>
                      <li>Automated E-mail Reporting</li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>
      <div className="parallax_solutions_four"></div>
      <Row id="agency-reporting">
        <Card id="card-holder-blue-solutions">
          <Card.Body>
            <Row>
              <Col>
                <Row align="center">
                  <h1>Agency Regulatory Reporting</h1>
                </Row>
                <br />
                <Row align="center">
                  <p
                    style={{
                      fontSize: "18px",
                      fontFamily: "Roboto, sans-serif",
                      paddingRight: "35px",
                      paddingLeft: "35px",
                    }}
                  >
                    Over the years our clients have consistently requested a
                    friendly and accurate regulatory reporting interface—a
                    request that is brilliantly delivered within PRAMS. The
                    regulatory reporting setup is swift, and our software
                    development team constantly monitors agencies for regulatory
                    changes and implements timely updates at no additional cost.
                    We are committed to developing the reports our Operators
                    need. Don’t see a state or agency let us know.
                  </p>
                </Row>
                <Row align="center">
                  <h3>Reports Included</h3>
                </Row>
                <br />
                <Row>
                  <Col
                    style={{
                      fontSize: "18px",
                      fontFamily: "Roboto, sans-serif",
                      minWidth: "250px",
                    }}
                  >
                    <ul>
                      <li>ONRR (OGOR)</li>
                      <li>Colorado Form 7</li>
                      <li>Michigan EQP 7101</li>
                      <li>Montana Forms 5/6</li>
                      <li>Louisiana OGP/R5D</li>
                    </ul>
                  </Col>
                  <Col
                    style={{
                      fontSize: "18px",
                      fontFamily: "Roboto, sans-serif",
                      minWidth: "250px",
                    }}
                  >
                    <ul>
                      <li>North Dakota Forms: 5/11/16</li>
                      <li>Nebraska Form 7A</li>
                      <li>New Mexico C-115/C-115B</li>
                      <li>South Dakota Form 5/5A</li>
                    </ul>
                  </Col>
                  <Col
                    style={{
                      fontSize: "18px",
                      fontFamily: "Roboto, sans-serif",
                      minWidth: "250px",
                    }}
                  >
                    <ul>
                      <li>Texas Form PR</li>
                      <li>Utah Forms 10/11</li>
                      <li>Wyoming Form 2</li>
                      <li>California OG110/OG110B/OG110D</li>
                    </ul>
                  </Col>
                </Row>
              </Col>
              <Col className="d-flex justify-content-center align-items-center">
                <img
                  id="solutions_pic_4"
                  src="solutions_pic_4.jpg"
                  alt="placeholder"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>
      <div className="parallax_solutions_five"></div>
      {/* <div className="parallax_solutions_last"></div> */}
      <Row
        id="footer"
        style={{ position: "relative", zIndex: "1", width: "100%", margin: 0 }}
      >
        <Card id="card-holder-footer-solutions">
          <Card.Body style={{ textAlign: "center" }}>
            <h5>
              Imagine how a digital workflow can impact your business. To
              schedule your live information session, call or email today!
            </h5>
            <h5>
              To send PRAMS an email click here
              <Button
                style={{ marginLeft: "5px" }}
                variant="primary"
                href="mailto:sales@pramsplus.com"
              >
                Contact Us
              </Button>
            </h5>
            <Row>
              <Col align="center" style={{ width: "80%", minWidth: "250px" }}>
                <h5>
                  <FontAwesomeIcon icon={faSquarePhone} /> (303)-904-6855
                </h5>
              </Col>
              <Col align="center" style={{ width: "80%", minWidth: "250px" }}>
                <h5>
                  <FontAwesomeIcon icon={faLocationDot} /> 6767 S. Spruce St, Suite 105, Centennial, CO 80112
                </h5>
              </Col>
              <Col align="center" style={{ width: "80%", minWidth: "250px" }}>
                <h5>
                  <FontAwesomeIcon icon={faEnvelope} /> sales@pramsplus.com
                </h5>
              </Col>
            </Row>
            <h5>
              <FontAwesomeIcon icon={faCopyright} /> PRAMS Plus LLC{" "}
              {new Date().getFullYear()}
            </h5>
          </Card.Body>
        </Card>
      </Row>
    </>
  );
};
export default Solutions;
