import React from "react";
import {
  Navbar,
  Container,
  Button,
  Nav,
  Offcanvas,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./TopNav.css";

const TopNav = () => {
  const navigate = useNavigate();

  return (
    <Navbar
      fixed="top"
      key={"lg"}
      expand={"lg"}
      style={{ backgroundColor: "#fff", maxHeight: "86px" }}
    >
      <Container fluid>
        <Navbar.Brand>
          <img
            id="pramsLogo"
            alt="Prams Logo"
            src="Prams_Logo_Text.jpg"
            // style={{ maxHeight: "70px",}}
            onClick={() => navigate("/")}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-lg`}
          aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
          placement="end"
          style={{width: "50%"}}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
              Menu
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link>
                <Button
                  id="navButton"
                  variant="outline-primary"
                  style={{ border: "none" }}
                  onClick={() => navigate("/")}>
                  Home
                </Button>
              </Nav.Link>
              <Nav.Link>
                <Button
                  id="navButton"
                  variant="outline-primary"
                  style={{ border: "none" }}
                  onClick={() => navigate("/solutions")}
                >
                  Solutions
                </Button>
              </Nav.Link>
              <Nav.Link>
                <Button
                  id="navButton"
                  variant="outline-primary"
                  style={{ border: "none" }}
                  onClick={() => navigate("/downloads")}
                >
                  Downloads
                </Button>
              </Nav.Link>
              <Nav.Link href="https://portal.pramsplus.com/Account/Login?ReturnUrl=%2F">
                <Button
                  id="navButton"
                  variant="outline-primary"
                  style={{ border: "none" }}
                >
                  Portal
                </Button>
              </Nav.Link>
              <Nav.Link href="https://www.goto.com/meeting/join">
                <Button
                  id="navButton"
                  variant="outline-primary"
                  style={{ border: "none" }}
                >
                  <img id="goto" alt="goto" src="goto.png" />
                </Button>
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default TopNav;
