import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faSquarePhone,
  faCopyright,
} from "@fortawesome/free-solid-svg-icons";
import "./Home.css";

const Home = () => {
  return (
    <>
      {/* <div className="parallax"></div> */}
      <div className="home-background">
        <Container fluid className="justify-content-md-center">
          <div
            style={{
              marginTop: "625px",
              marginLeft: "auto",
              marginRight: "auto",
              maxHeight: "500px",
              maxWidth: "1700px",
            }}
          >
            <Row id="innovate" style={{ position: "relative", zIndex: "1" }}>
              <Card id="card-holder-blue">
                <Card.Body style={{ textAlign: "center" }}>
                  <Row>
                    <h1> Innovative Oil & Gas Production Software</h1>
                  </Row>
                  <br />
                  <Row>
                    <Col id="innovate-col-1">
                      <h2>Complete Field Solution</h2>
                      <br />
                      <p
                        style={{
                          fontSize: "18px",
                          fontFamily: "Roboto, sans-serif",
                        }}
                      >
                        Built through years of continuous development, PRAMS is
                        a complete field solution created with the most dynamic
                        gauge-sheet style data capture tool. Unlike our
                        competitors, tanks, site inspections, unlimited meters,
                        and equipment metrics - all at the pumpers fingertip's.
                      </p>
                    </Col>
                    <Col id="innovate-col-2">
                      <h2>Asset Management & Integrations</h2>
                      <br />
                      <p
                        style={{
                          fontSize: "18px",
                          fontFamily: "Roboto, sans-serif",
                        }}
                      >
                        Today, every well and battery are installed with
                        countless meters and sensors, it is becoming
                        increasingly difficult to manage everything efficiently.
                        The PRAMS platform enables both digital data and pumper
                        captured data to come together into a single platform. A
                        single platform from which all departments can rely upon
                        for accurate information. Integrations with other
                        departmental workflows become seamless.
                      </p>
                    </Col>
                    <Col id="innovate-col-3">
                      <h2>Powerful Analytic Capabilities</h2>
                      <br />
                      <p
                        style={{
                          paddingLeft: "10px",
                          fontSize: "18px",
                          fontFamily: "Roboto, sans-serif",
                        }}
                      >
                        At PRAMS we understand that just capturing the data
                        isn't enough. Too often important data and trends are
                        overlooked, having this information transformed into
                        meaningful visualization tools and concise reports helps
                        you gain insight into your assets and maximize your ROI,
                        that is the PRAMS way.
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Row>
            <Row
              id="card-info"
              style={{
                position: "relative",
                marginTop: "-30px",
                marginBottom: "-30px",
                zIndex: "0",
              }}
            >
              <Card style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                <Card.Body>
                  <br />
                  <Row className="text-center">
                    <h1 style={{ color: " rgb(2,46,105)" }}>
                      PRAMS Benefits the Entire Organization
                    </h1>
                  </Row>
                  <br />
                  <Row>
                    <Col align="center">
                      <div className="flip-card">
                        <div className="flip-card-inner">
                          <div className="flip-card-front">
                            <div className="cardTitle">Accounting</div>
                            <div className="card-img-cont">
                              <img
                                src={"accounting.png"}
                                alt="Avatar"
                                className="card-img"
                              />
                            </div>
                          </div>

                          <div className="flip-card-back">
                            <div className="cardTitle"></div>
                            <div className="textCont">
                              <p>
                                Reconcile monthly purchaser statements to daily
                                field data for both oil and gas. Never miss
                                getting paid on a crude oil haul again.
                              </p>
                              <p>
                                Comparison of water hauling invoices to daily
                                field data improves approval integrity.
                              </p>
                              <p>
                                Daily field data enables the financial team to
                                develop more accurate and timely accruals.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col align="center">
                      <div className="flip-card">
                        <div className="flip-card-inner">
                          <div className="flip-card-front">
                            <div className="cardTitle">Regulatory</div>
                            <div className="card-img-cont">
                              <img
                                src={"regulatory.png"}
                                alt="Avatar"
                                className="card-img"
                              />
                            </div>
                          </div>

                          <div className="flip-card-back">
                            <div className="cardTitle"></div>
                            <div
                              className="textCont"
                              style={{
                                fontSize: "18px",
                                fontFamily: "Roboto, sans-serif",
                              }}
                            >
                              <p>
                                Seamless State and Federal reporting reduces
                                reporting errors and non-compliance notices.
                              </p>
                              <p>
                                Log and store inspections (APCE, Facility, AVO,
                                etc) for timely response to compliance requests.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col align="center">
                      <div className="flip-card">
                        <div className="flip-card-inner">
                          <div className="flip-card-front">
                            <div className="cardTitle">Operations</div>
                            <div className="card-img-cont">
                              <img
                                src={"operations.jpeg"}
                                alt="Avatar"
                                className="card-img"
                              />
                            </div>
                          </div>

                          <div className="flip-card-back">
                            <div className="cardTitle"></div>
                            <div className="textCont">
                              <p>
                                Easily review daily production volumes,
                                pressures and other well data to optimize
                                production.
                              </p>
                              <p>
                                Track equipment operational metrics reducing
                                unscheduled downtime and lease operating
                                expenses.
                              </p>
                              <p>
                                Manage tank capacities improving timing of water
                                hauls and crude sales.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col align="center">
                      <div className="flip-card">
                        <div className="flip-card-inner">
                          <div className="flip-card-front">
                            <div className="cardTitle">Marketing</div>
                            <div className="card-img-cont">
                              <img
                                src={"marketing.png"}
                                alt="Avatar"
                                className="card-img"
                              />
                            </div>
                          </div>

                          <div className="flip-card-back">
                            <div className="cardTitle"></div>
                            <div
                              className="textCont"
                              style={{
                                fontSize: "18px",
                                fontFamily: "Roboto, sans-serif",
                              }}
                            >
                              <p>
                                Daily management of tank capacities enables
                                timing of crude sales.
                              </p>
                              <p>
                                Monitor daily gas flows to avoid imbalance
                                penalties.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col align="center">
                      <div className="flip-card">
                        <div className="flip-card-inner">
                          <div className="flip-card-front">
                            <div className="cardTitle">Land</div>
                            <div className="card-img-cont">
                              <img
                                src={"land.jpg"}
                                alt="Avatar"
                                className="card-img"
                              />
                            </div>
                          </div>

                          <div className="flip-card-back">
                            <div className="cardTitle"></div>
                            <div
                              className="textCont"
                              style={{
                                fontSize: "18px",
                                fontFamily: "Roboto, sans-serif",
                              }}
                            >
                              <p>
                                Well downtime monitoring avoids unnecessary
                                lease terminations for leases past the primary
                                term.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col align="center">
                      <div className="flip-card">
                        <div className="flip-card-inner">
                          <div className="flip-card-front">
                            <div className="cardTitle">Reserves</div>
                            <div className="card-img-cont">
                              <img
                                src={"reserves.jpeg"}
                                alt="Avatar"
                                className="card-img"
                              />
                            </div>
                          </div>

                          <div className="flip-card-back">
                            <div className="cardTitle"></div>
                            <div className="textCont">
                              <p>
                                Simplifying transfer of daily and monthly
                                production volumes, pressures and operational
                                data to the reserve engineering package provides
                                more time for evaluating forecasts when timing
                                is compressed.
                              </p>
                              <p>
                                Combining real-time production data and with the
                                reserves forecast allows operators to focus on
                                the under-performing wells quicker.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Row>
            <Row id="pricing" style={{ position: "relative", zIndex: "1" }}>
              <Card id="card-holder-blue-2">
                <Card.Body>
                  <Row className="text-center">
                    <h1>PRAMS Pricing: No Surprises</h1>
                  </Row>
                  <br />
                  <Row
                    className="d-flex align-items-center "
                    style={{ margin: 0, paddingLeft: "15px" }}
                  >
                    <Row className="text-center">
                      <h3 className="header-white">
                        Starting at $5/well/month
                      </h3>
                    </Row>
                    <br />
                    <Row>
                      <Row className="text-center">
                        <h4>Highlights</h4>
                      </Row>
                      <br />
                      <Row className="justify-content-md-center">
                        <Col
                          xs={4}
                          className="d-flex align-items-center"
                          style={{
                            minWidth: "250px",
                            fontSize: "18px",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          <ul>
                            <li>Hosted or On-premises</li>
                            <li>Unlimited Users</li>
                            <li>Continuous Software Updates Included</li>
                            <li>
                              Month-to-month Contract, We Earn Your Business
                            </li>
                          </ul>
                        </Col>
                        <Col
                          xs={3}
                          className="d-flex align-items-center"
                          style={{
                            minWidth: "250px",
                            fontSize: "18px",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          <ul>
                            <li>Access to the Entire PRAMS Platform</li>
                            <li>No Yearly Renewal Fees</li>
                            <li>Free Call Support</li>
                            <li>Personalized Queries</li>
                          </ul>
                        </Col>
                      </Row>
                    </Row>
                  </Row>
                  <Row>
                    <Row className="text-center">
                      <h3>
                        Additional Services: Contact us for pricing and more
                        information
                      </h3>
                    </Row>
                    <br />
                    <br />
                    <br />
                    <br />
                    <Row className="text-center">
                      <Col>
                        <h4
                          className="header-white"
                          // style={{
                          //   marginLeft: "auto",
                          //   marginRight: "auto",
                          //   width: "85%",
                          //   height: "45px",
                          // }}
                        >
                          Data Migrations
                        </h4>
                        <p
                          style={{
                            paddingLeft: "15px",
                            paddingRight: "15px",
                            minWidth: "250px",
                            fontSize: "18px",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          One of the biggest obstacles of implementing a new
                          software platform is migrating old data into the new
                          database. The good news is that so many other
                          companies have grown fed up with their previous
                          software providers and switched to PRAMS that we have
                          imports designed to bring data in the PRAMS platform
                          from many vendor as well as spreadsheets.
                        </p>
                      </Col>
                      <Col>
                        <h4
                          className="header-white"
                          // style={{
                          //   marginLeft: "auto",
                          //   marginRight: "auto",
                          //   width: "85%",
                          //   height: "45px",
                          // }}
                        >
                          Hosting Option
                        </h4>
                        <p
                          style={{
                            paddingLeft: "15px",
                            paddingRight: "15px",
                            minWidth: "250px",
                            fontSize: "18px",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          Server infrastructure and IT support can quickly
                          become an expensive overhead for smaller cap
                          companies. To help combat those expenses, PRAMS offers
                          companies the option to host their data on one of
                          PRAMS proprietary servers. With seamless integration
                          capabilities, we can remove the burden and costs of
                          managing your servers and databases.
                        </p>
                      </Col>
                    </Row>
                  </Row>
                </Card.Body>
              </Card>
            </Row>
            <Row
              id="prams-way"
              style={{
                position: "relative",
                marginTop: "-30px",
                marginBottom: "-30px",
                zIndex: "0",
              }}
            >
              <Card style={{ paddingTop: "30px", paddingBottom: "45px" }}>
                <Card.Body>
                  <br />
                  <Row align="center">
                    <h1 className="header-white">The PRAMS Way</h1>
                  </Row>
                  <br />
                  <Row>
                    <Col align="center">
                      <img
                        id="the_prams_way_logo"
                        src="simplify_prams_logo.png"
                        alt="placeholder"
                      />
                    </Col>
                    <Col
                      align="center"
                      style={{
                        fontWeight: "500",
                        color: "var(--pramsBlue)",
                        fontSize: "18px",
                        fontFamily: "Roboto, sans-serif",
                      }}
                    >
                      <p>
                        PramsX2 is an end-to-end oil and gas production
                        management software designed to conquer the complex
                        data-management challenges facing oil and gas operators.
                        The ability to rapidly and accurately capture, analyze,
                        and manage production data is vital to the success of
                        every oil and gas operator. PRAMS enables operators to
                        capture, analyze, and distribute information with
                        confidence and consistency.
                      </p>
                      <p>
                        By simplifying field data capture through single-point
                        data entry, we equip pumpers, foreman, engineers,
                        analysts, and operators with the ability to effectively
                        manage what they measure.
                      </p>
                      <p>
                        The use of an integrated production system simplifies
                        data acquisition and allows everyone in the organization
                        to utilize the production information they need to do
                        their jobs. Anyone can easily see, review and export
                        daily and historical production data using flexible and
                        customizable processes.
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Row>
            <Row id="footer" style={{ position: "relative", zIndex: "1" }}>
              <Card id="card-holder-footer">
                <Card.Body style={{ textAlign: "center" }}>
                  <h5>
                    Imagine how a digital workflow can impact your business. To
                    schedule your live information session, call or email today!
                  </h5>
                  <h5>
                    To send PRAMS an email click here
                    <Button
                      style={{ marginLeft: "5px" }}
                      variant="primary"
                      href="mailto:sales@pramsplus.com"
                    >
                      Contact Us
                    </Button>
                  </h5>
                  <Row>
                    <Col
                      align="center"
                      style={{ width: "80%", minWidth: "250px" }}
                    >
                      <h5>
                        <FontAwesomeIcon icon={faSquarePhone} /> (303)-904-6855
                      </h5>
                    </Col>
                    <Col
                      align="center"
                      style={{ width: "80%", minWidth: "250px" }}
                    >
                      <h5>
                        <FontAwesomeIcon icon={faLocationDot} /> 6767 S. Spruce St, Suite 105, Centennial, CO 80112
                      </h5>
                    </Col>
                    <Col
                      align="center"
                      style={{ width: "80%", minWidth: "250px" }}
                    >
                      <h5>
                        <FontAwesomeIcon icon={faEnvelope} />{" "}
                        sales@pramsplus.com
                      </h5>
                    </Col>
                  </Row>
                  <h5>
                    <FontAwesomeIcon icon={faCopyright} /> PRAMS Plus LLC{" "}
                    {new Date().getFullYear()}
                  </h5>
                </Card.Body>
              </Card>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};
export default Home;
