import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import TopNav from "./Components/TopNav";
import Home from "./Pages/Home";
import Solutions from "./Pages/Solutions";
import Downloads from "./Pages/Downloads";
import PrivacyPolicy from "./Pages/privacy_policy";
import BadPage from "./Pages/404";
import "./App.css";

function App() {
  return (
    <>
      <Router>
        <TopNav />
        <Container
          fluid
          style={{ height: "100vh", maxWidth: "100vw", padding: "0", overflowX: "hidden" }}
        >
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/solutions" element={<Solutions />}></Route>
            <Route path="/downloads" element={<Downloads />}></Route>
            <Route path="/privacy-policy-mobile" element={<PrivacyPolicy />}></Route>
            <Route path="*" element={<BadPage />}></Route>
          </Routes>
        </Container>
      </Router>
    </>
  );
}

export default App;
