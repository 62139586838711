import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import "./Downloads.css";

const Downloads = () => {
  return (
    <Container fluid style={{ marginTop: "160px", overflowY: "hidden" }}>
      <Card id="downloads-card">
        <Card.Body>
          <Row style={{ justifyContent: "center" }}>
            <Col xs={8} style={{ textAlign: "center" }}>
              <h1 id="downloads-header">PRAMS Software Downloads</h1>
              <p
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                Below are the links to download installation packages for the
                different components of the PRAMS platform. While you can
                download the installers at any time to run them you will need
                additional information and/or a DKF file. Your company’s PRAMS
                administrator will provide this to you.
              </p>
            </Col>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Col
              xs={7}
              align="center"
              style={{
                minWidth: "250px",
                fontFamily: "Roboto, sans-serif",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              <p>
                System Requirements Windows 7,8,10,or 11{" "}
                <span style={{ color: "red", fontWeight: "bolder" }}>
                  (Will not work on Apple Macs or on Google Chromebooks)
                </span>
              </p>
              <p>
                2GB of RAM 100MB of free disk space 900x600 screen resolution or
                higher TCP/IP network connection
              </p>
            </Col>
          </Row>
          <br />
          <Row style={{ justifyContent: "center" }}>
            <Col xs={3} align="center" style={{ minWidth: "250px" }}>
              <a
                href="https://update.pramsplus.com/file/download?id=183"
                className="btn-slide2"
              >
                <span className="circle2">
                  <FontAwesomeIcon icon={faDownload} className="iconDl" />
                </span>
                <span className="title2">PUMPER X2</span>
                <span className="title-hover2">Click Here</span>
              </a>
            </Col>
            <Col xs={6} style={{ minWidth: "250px" }}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  fontFamily: "Roboto, sans-serif",
                }}
              >
                <span style={{fontWeight: "1000"}}>PumperX2</span> is the daily data capture tool used by
                operators to capture daily data. It can be used off line in the
                field or in direct mode if a network connection is available. In
                order to use this installer you must have your company ID,
                pumper ID and password.
              </p>
            </Col>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Col xs={3} align="center" style={{ minWidth: "250px" }}>
              <a
                href="https://update.pramsplus.com/file/download?id=2361"
                className="btn-slide2"
              >
                <span className="circle2">
                  <FontAwesomeIcon icon={faDownload} className="iconDl" />
                </span>
                <span className="title2">INSIGHT X2</span>
                <span className="title-hover2">Click Here</span>
              </a>
            </Col>
            <Col xs={6} style={{ minWidth: "250px" }}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  fontFamily: "Roboto, sans-serif",
                }}
              >
                <span style={{fontWeight: "1000"}}>InsightX2</span> is the data presentation software that
                allows you to look at charts and data, run internal reports and
                extract data for offline use. This software is usually used by
                all users at a company who need to view or extract production
                data. To install this software you must have a valid DKF file
                available.
              </p>
            </Col>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Col xs={3} align="center" style={{ minWidth: "250px" }}>
              <a
                href="https://update.pramsplus.com/file/download?id=180"
                className="btn-slide2"
              >
                <span className="circle2">
                  <FontAwesomeIcon icon={faDownload} className="iconDl" />
                </span>
                <span className="title2">ANALYST X2</span>
                <span className="title-hover2">Click Here</span>
              </a>
            </Col>
            <Col xs={6} style={{ minWidth: "250px" }}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  fontFamily: "Roboto, sans-serif",
                }}
              >
                <span style={{fontWeight: "1000"}}>AnalystX2</span> is the main application used by the
                Production Analyst to manage the PRAMS system. This software is
                normally used only by individuals who are responsible for
                managing the production processes. To install this software you
                must have a valid DKF file available.
              </p>
            </Col>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Col xs={3} align="center" style={{ minWidth: "250px" }}>
              <a
                href="https://update.pramsplus.com/file/download?id=179"
                className="btn-slide2"
              >
                <span className="circle2">
                  <FontAwesomeIcon icon={faDownload} className="iconDl" />
                </span>
                <span className="title2">SERVER</span>
                <span className="title-hover2">Click Here</span>
              </a>
            </Col>
            <Col xs={6}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  fontFamily: "Roboto, sans-serif",
                }}
              >
                <span style={{fontWeight: "1000"}}>Server: </span>
                The server installer is used only by the company’s IT
                administrator to install the necessary server and database
                components for the PRAMSX2 system. You will need additional
                information provided by your PRAMS support staff to use this
                installer.
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};
export default Downloads;
